<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="订单信息">
        <icon-button
            slot="extra"
            class="icon-btn"
            icon="return"
            @click.native="goBack"
        />
        <icon-button
            slot="extra"
            class="icon-btn"
            icon="next"
            @click.native="goNext"
        />
        <div class="order-info">
          <div style="width: 50%">
            <el-descriptions :column="1" border size="small">
              <el-descriptions-item>
                <template slot="label" style="width: 40%"> 订单号码</template>
                {{ detailInfo.orderCode }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 城区</template>
                {{ detailInfo.regionName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 停车场</template>
                {{ detailInfo.parklotName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 泊位号</template>
                {{ detailInfo.parkspaceCode }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 车牌号</template>
                <Numplate :type="detailInfo.autoType">{{
                    detailInfo.numplate
                  }}
                </Numplate>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 驶入时间</template>
                {{ detailInfo.intoDateTimeFormat }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 驶出时间</template>
                {{ detailInfo.exitDateTimeFormat }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 停车时长</template>
                {{ detailInfo.parkingTime }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 订单金额（元）</template>
                <span
                    v-if="detailInfo.receivableAmount !== ''">{{ parseFloat(detailInfo.receivableAmount).toFixed(2) }}</span>
              </el-descriptions-item>
              <!-- <el-descriptions-item>
                <template slot="label"> 订单来源 </template>
                {{detailInfo.sourceType}}
              </el-descriptions-item> -->
              <el-descriptions-item>
                <template slot="label"> 置信度</template>
                {{ detailInfo.confidence }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 巡检员</template>
                {{ detailInfo.inspectorName }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="photo-info">
            <div class="photo-title">车辆图片</div>
            <div>
              <el-carousel height="332px" trigger="click">
                <el-carousel-item v-for="item in detailInfo.photos" :key="item.index">
                  <el-image
                      :preview-src-list="detailInfo.photos"
                      :src="item"
                  ></el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="btn">
              <el-button size="small" type="success" @click="confirmOrder"
              >确认订单
              </el-button
              >
              <el-button size="small" type="danger" @click="cancelOrder"
              >取消订单
              </el-button
              >
              <el-button
                  plain
                  size="small"
                  type="primary"
                  @click="modifyLicensePlate"
              >修改车牌
              </el-button
              >
              <el-button plain size="small" @click="patrolTask"
              >巡检任务
              </el-button
              >
            </div>
          </div>
        </div>
      </easy-card>
    </div>
    <!-- <div class="content-box section" style="margin-top: 18px">
      <easy-card title="订单记录">
        <el-table
          :data="orderRecordInfo"
          border
          style="width: 100%"
          class="common-table"
          size="medium"
          v-loading="isRecordLoading"
        >
          <el-table-column
            prop="type"
            label="记录类型"
            width="300"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="operate" label="操作详情" align="center">
          </el-table-column>
          <el-table-column
            prop="operator"
            label="操作人"
            width="300"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="time"
            label="操作时间"
            width="400"
            align="center"
          >
          </el-table-column>
        </el-table>
      </easy-card>
    </div> -->
    <div class="content-box section" style="margin-top: 18px">
      <easy-card title="用户信息">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 用户编号</template>
            {{ detailInfo.userCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 注册来源</template>
            {{ registSource[detailInfo.registSource] }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 注册时间</template>
            {{ detailInfo.userRegisterDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 最后登录时间</template>
            {{ detailInfo.loginLastDate }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
    </div>

    <!-- 取消订单弹窗 -->
    <el-dialog
        :append-to-body="true"
        :before-close="cancelDialogBeforeClosed"
        :visible.sync="cancelDialogFormVisible"
        class="common-dialog"
        title="取消订单"
        width="650px"
    >
      <div v-loading="isCancelLoading">
        <el-form
            ref="cancelForm"
            :model="cancelForm"
            :rules="cancelRules"
            label-width="100px"
        >
          <el-form-item label="原因描述" prop="modelId">
            <el-select
                v-model="cancelForm.modelId"
                placeholder="请选择取消原因"
                style="width:100%"
                @change="changeReson"
            >
              <el-option
                  v-for="item in cancelOptions"
                  :key="item.id"
                  :label="item.modeName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详细描述" prop="remake" required>
            <el-input
                v-model="cancelForm.remake"
                :autosize="{ minRows: 3}"
                maxlength="100"
                show-word-limit
                type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;">
          <el-button @click="closeCancel">取消</el-button>
          <el-button type="primary" @click="cancelSubmit">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 修改车牌弹窗 -->
    <el-dialog
        :append-to-body="true"
        :before-close="modifyDialogBeforeClosed"
        :visible.sync="modifyDialogFormVisible"
        class="common-dialog"
        title="修改车牌"
        width="550px"
    >
      <div v-loading="isModifyLoading">
        <el-form
            ref="modifyForm"
            :model="modifyForm"
            :rules="modifyRules"
            label-width="100px"
        >
          <el-form-item label="车牌颜色" required>
            <el-form-item prop="plateType">
              <el-select
                  v-model="modifyForm.plateType"
                  placeholder="请选择车牌颜色"
              >
                <el-option label="黄牌" value="01"></el-option>
                <el-option label="蓝牌" value="02"></el-option>
                <el-option label="黑牌" value="06"></el-option>
                <el-option label="绿色" value="03"></el-option>
                <el-option label="黄绿" value="04"></el-option>
              </el-select>
            </el-form-item>
          </el-form-item>
          <el-form-item label="车牌号码" prop="plateNumber" required>
            <el-input v-model="modifyForm.plateNumber"></el-input>
          </el-form-item>
          <el-form-item label="修改原因" prop="remake" required>
            <el-input
                v-model="modifyForm.remake"
                maxlength="100"
                show-word-limit
                type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;">
          <el-button @click="closeModify">取消</el-button>
          <el-button type="primary" @click="modifySubmit">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 巡检任务弹窗 -->
    <el-dialog
        :append-to-body="true"
        :before-close="patrolDialogBeforeClosed"
        :visible.sync="patrolDialogFormVisible"
        class="common-dialog"
        title="巡检任务"
        width="550px"
    >
      <div v-loading="isPatrolLoading">
        <el-form
            ref="patrolForm"
            :model="patrolForm"
            :rules="patrolRules"
            label-width="100px"
        >
          <el-form-item label="第一步" prop="modelId" required>
            <el-select
                v-model="patrolForm.modelId"
                placeholder="请选择任务类型"
                @change="isShowSecond"
            >
              <el-option label="查车牌" value="7F2CD5D5A2EF40BC86EDCD7699694A59"></el-option>
              <el-option label="清异物" value="B4BA4AD4C598463F93AE451BB6645A48"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
              v-if="showSecond"
              label="第二步"
              prop="remake"
          >
            <el-select v-model="patrolForm.remake" placeholder="请选择查车牌原因">
              <el-option
                  v-for="item in patrolOptions"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;">
          <el-button @click="closePatrol">取消</el-button>
          <el-button type="primary" @click="patrolSubmit">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  confirmManualAudit,
  deleteManualAudit,
  getAuditRecords,
  getNextOrderDetailByOrderId,
  getoperationall,
  getOrderDetailByOrderId,
  updateManualAuditWithCheckNum,
  updatePlateNum
} from "@/api/manualReview";
import Numplate from "@/components/Numplate";

export default {
  name: "order_processing",
  components: {
    Numplate,
  },
  data() {
    return {
      inspectorId: "",
      parkingOrderId: "",
      parkingLotId: "",
      parkingSpaceId: "",
      detailInfo: {}, //订单详情
      registSource: {
        "1": "IOS",
        "2": "安卓",
        "3": "网站",
        "4": "微信",
        "5": "城市运维平台",
        "6": "运营端",
        "7": "停车场管理平台",
        "9": "商户端",
        "10": "岗亭端",
        "11": "小程序",
        "12": "结算方",
        "13": "机关单位巡检",
      },
      orderRecordInfo: [], //订单记录
      isRecordLoading: false,
      imgDataList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      photoList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      modifyDialogFormVisible: false, //修改车牌弹窗
      isModifyLoading: false,
      //修改车牌表单
      modifyForm: {
        orderId: "",
        modelId: "1F567DAC5E7248C4A193FE21FDBC59F1",
        plateType: "",
        plateNumber: "",
        remake: "",
        entrance: "",
        driveType: "",
        parkingLotId: "",
      },
      modifyRules: {
        plateType: [
          {required: true, message: "请选择车牌颜色", trigger: "change"},
        ],
        plateNumber: [
          {
            required: true, validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("车牌号不能为空"));
              } else if (
                  !/^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([a-zA-Z]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[a-zA-Z]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/.test(
                      value
                  )
              ) {
                callback(
                    new Error("车牌号码格式不正确")
                );
              } else {
                callback();
              }
            }, trigger: "blur"
          },
        ],
        remake: [
          {required: true, message: "请输入修改原因", trigger: "blur"},
          {max: 100, message: "长度不能超过100个字符", trigger: "blur"},
        ],
      },
      cancelDialogFormVisible: false, //取消订单弹窗
      isCancelLoading: false,
      // cancelOptions: [
      //   { label: "无车牌" },
      //   { label: "看不清车牌" },
      //   { label: "非机动车辆" },
      //   { label: "其他原因" },
      // ],
      cancelOptions: [],
      //取消订单表单
      cancelForm: {
        entrance: "",
        driveType: "",
        orderId: "",
        modelId: "",
        remake: "",
        parkingLotId: "",
      },
      cancelRules: {
        modelId: [
          {required: true, message: "请选择取消原因"}
        ],
        remake: [
          {required: true, message: "请输入修改原因", trigger: "blur"},
          {max: 100, message: "长度不能超过100个字符", trigger: "blur"},
        ],
      },
      patrolDialogFormVisible: false, //巡检任务弹窗
      isPatrolLoading: false,
      showSecond: false,
      //巡检表单
      patrolForm: {
        modelId: "",
        remake: "",
        orderId: "",
        parkingLotId: "",
      },
      patrolRules: {
        modelId: [{required: true, message: "请选择巡检任务类型"}],
        remake: [{required: true, message: "请选择详细原因"}],
      },
      patrolOptions: [
        {label: "光线过强/弱"},
        {label: "车辆停放偏离摄像头拍摄区域,停放不规范"},
        {label: "泊位有车但看不到车牌,停放不规范"},
        {label: "驶入照片不清晰"},
      ],
    };
  },
  methods: {
    //删除
    goBack() {
      this.$router.back();
    },
    //获取下一条数据
    goNext() {
      getNextOrderDetailByOrderId(this.parkingOrderId).then((res) => {
        if (res && res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.parkingOrderId = returnObject.id;
          this.parkingLotId = returnObject.parkingLotInfoId;
          this.parkingSpaceId = returnObject.parkingSpaceId;
          this.detailInfo = {
            orderCode: returnObject.orderCode,
            regionName: returnObject.regionName,
            parklotName: returnObject.parklotName,
            parkspaceCode: returnObject.parkspaceCode,
            numplate: returnObject.numplate,
            autoType: returnObject.autoType,
            intoDateTimeFormat: returnObject.intoDateTimeFormat,
            exitDateTimeFormat: returnObject.exitDateTimeFormat,
            parkingTime: returnObject.parkingTime,
            receivableAmount: returnObject.receivableAmount,
            sourceType: returnObject.sourceType,
            confidence: returnObject.confidence,
            inspectorName: returnObject.inspectorName,
            photos: returnObject.photos,
            exitphotos: returnObject.exitphotos,
            userCode: returnObject.userCode,
            registSource: returnObject.registSource,
            userRegisterDate: returnObject.userRegisterDate,
            loginLastDate: returnObject.loginLastDate,
          };
        }
      });
    },
    //修改车牌提交
    modifySubmit() {
      this.$refs.modifyForm.validate((valid) => {
        if (valid) {
          const params = this.modifyForm;
          params.orderId = this.parkingOrderId;
          params.parkingLotId = this.parkingLotId;
          this.isModifyLoading = true;
          updatePlateNum(params).then((res) => {
            this.isModifyLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "修改车牌成功",
                type: "success",
              });
              this.modifyDialogFormVisible = false;
              this.getOrderDetail();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //修改车牌弹窗关闭时清理表单
    modifyDialogBeforeClosed(done) {
      this.$refs.modifyForm.resetFields();
      done();
    },
    //关闭修改车牌
    closeModify() {
      this.modifyDialogFormVisible = false;
      this.$refs.modifyForm.resetFields();
    },
    //修改车牌弹窗
    modifyLicensePlate() {
      this.modifyDialogFormVisible = true;
    },
    //打开取消订单弹窗
    cancelOrder() {
      this.cancelDialogFormVisible = true;
    },
    //取消订单快捷描述选项改变
    changeReson(e) {
      const list = this.cancelOptions;
      const reason = list.filter(item => {
        return item.id === e
      });
      this.cancelForm.remake = reason[0].modeName
    },
    //关闭取消订单弹窗
    closeCancel() {
      this.cancelDialogFormVisible = false;
      this.$refs.cancelForm.resetFields();
    },
    //关闭取消订单弹窗时清理表单
    cancelDialogBeforeClosed(done) {
      this.$refs.cancelForm.resetFields();
      done();
    },
    //取消订单提交
    cancelSubmit() {
      this.$refs.cancelForm.validate((valid) => {
        if (valid) {
          const params = this.cancelForm;
          params.orderId = this.parkingOrderId;
          params.parkingLotId = this.parkingLotId;
          this.isCancelLoading = true;
          deleteManualAudit(
              params
          ).then((res) => {
            this.isCancelLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "已取消订单",
                type: "success",
              });
              this.cancelDialogFormVisible = false;
              this.goNext();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //巡检任务弹窗
    patrolTask() {
      this.patrolDialogFormVisible = true;
    },
    //是否显示第二步
    isShowSecond(e) {
      if (e === "7F2CD5D5A2EF40BC86EDCD7699694A59") {
        this.showSecond = true;
      } else {
        this.showSecond = false;
        this.patrolForm.remake = "";
      }
    },
    //关闭取消订单弹窗
    closePatrol() {
      this.patrolDialogFormVisible = false;
      this.$refs.patrolForm.resetFields();
    },
    //关闭取消订单弹窗时清理表单
    patrolDialogBeforeClosed(done) {
      this.$refs.patrolForm.resetFields();
      done();
    },
    //巡检任务提交
    patrolSubmit() {
      this.$refs.patrolForm.validate((valid) => {
        if (valid) {
          const params = this.patrolForm;
          params.orderId = this.parkingOrderId;
          params.parkingLotId = this.parkingLotId;
          params.parkingSpaceId = this.parkingSpaceId;
          this.isPatrolLoading = true;
          updateManualAuditWithCheckNum(
              params
          ).then((res) => {
            this.isPatrolLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "巡检任务下发成功",
                type: "success",
              });
              this.patrolDialogFormVisible = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //确认订单
    confirmOrder() {
      this.$confirm("您要确认当前订单无误吗?", "确认订单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const orderId = this.parkingOrderId;
        const parkingLotId = this.parkingLotId;
        confirmManualAudit(orderId, parkingLotId).then((res) => {
          if (res && res.code === 30 && res.result) {
            this.$message.success("订单确认成功！");
            this.goNext();
          }
        });
      });
    },

    //查询人工审核详情
    getOrderDetail() {
      const orderId = this.parkingOrderId;
      const inspector = this.inspectorId;
      getOrderDetailByOrderId(orderId, inspector).then((res) => {
        if (res && res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.parkingOrderId = returnObject.id;
          this.parkingLotId = returnObject.parkingLotInfoId;
          this.parkingSpaceId = returnObject.parkingSpaceId;
          this.detailInfo = {
            orderCode: returnObject.orderCode,
            regionName: returnObject.regionName,
            parklotName: returnObject.parklotName,
            parkspaceCode: returnObject.parkspaceCode,
            numplate: returnObject.numplate,
            autoType: returnObject.autoType,
            intoDateTimeFormat: returnObject.intoDateTimeFormat,
            exitDateTimeFormat: returnObject.exitDateTimeFormat,
            parkingTime: returnObject.parkingTime,
            receivableAmount: returnObject.receivableAmount,
            sourceType: returnObject.sourceType,
            confidence: returnObject.confidence,
            inspectorName: returnObject.inspectorName,
            photos: returnObject.photos,
            exitphotos: returnObject.exitphotos,
            userCode: returnObject.userCode,
            registSource: returnObject.registSource,
            userRegisterDate: returnObject.userRegisterDate,
            loginLastDate: returnObject.loginLastDate,
          };
        }
      });
    },
    //查询人工审核记录
    getRecords() {
      const orderId = this.parkingOrderId;
      this.isRecordLoading = true;
      getAuditRecords(orderId).then((res) => {
        this.isRecordLoading = false;
        if (res && res.code === 30 && res.result) {
          this.orderRecordInfo = res.returnObject;
        }
      });
    },
    //查询原因列表
    getoperationall() {
      getoperationall().then(res => {
        if (res.code === 30 && res.result) {
          const list = res.returnObject;
          this.cancelOptions = list.filter((item => {
            return item.handleId === "P1" || item.handleId === "P2"
          }))
        }
      })
    },
  },
  mounted() {
    this.parkingOrderId = this.$route.query.parkingOrderId;
    this.inspectorId = this.$route.query.inspectorId;
    this.getOrderDetail();
    // this.getRecords();
    this.getoperationall();
  },
};
</script>

<style lang="less" scoped>
.icon-btn {
  float: right;
  margin-right: 10px;
}

/deep/ .el-descriptions--small.is-bordered .el-descriptions-item__cell {
  width: 40%;
}

.order-info {
  display: flex;
  flex-direction: row;
}

.photo-info {
  width: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ebeef5;

  .photo-title {
    width: 100%;
    background: #fafafa;
    height: 36px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    padding: 0 160px;
    justify-content: space-between;
  }
}
</style>
